/*
 * Copyright 2019 LABOR.digital
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Last modified: 2019.05.15 at 18:10
 */
import "./App.less";
import "./Components/Bookmark/Bookmark";
import "./Components/ContentTools/ContentTools";
import "./Components/Fancybox/Fancybox";
import "./Components/Files/Files";
import "./Components/Modal/Modal";
import "./Components/DatePicker/DatePicker";
import "./Components/Page/Page";
import "./Components/PageNode/PageNode";
import "./Components/ResponsiveTable/ResponsiveTable";
import "./Components/Sidebar/Sidebar";
import "./Components/Table/Table";
import "./Components/Toggleable/Toggleable";

declare global {
	const $: any;
}

$(() => {
	$(document).trigger("domChange");
});
