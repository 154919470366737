/*
 * Copyright 2019 LABOR.digital
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Last modified: 2019.05.15 at 17:17
 */
import "./Modal.less";

(function () {

	G8Framework.addGlobalListener("domChange", _initClass);

	function _initClass(scope) {
		$Self("*[data-modal]", scope).getUninitializedOnly("G8.Modal").each(function (e) {
				var $modal = $(this);
				var $close = $modal.find("*[data-modal-close]");
				var $overlay = $modal.find("*[data-modal-overlay]");

				$close.bind("click", function (e) {
					e.preventDefault();
					$modal.trigger("modalClose");
					return false;
				});

				$modal.bind("modalOpen", function () {
					$modal.addClass("modal__container--visible");
					$("body").addClass("modal__body--visible");
				});
				$modal.bind("modalClose", function () {
					$modal.find("video").each(function () {
						var $video = $(this);
						var video = $video.get(0);

						video.pause && video.pause();
					});

					$modal.removeClass("modal__container--visible");
					$("body").removeClass("modal__body--visible");
				});
			})
			.filter("*[data-modal-open-on-load]").trigger("modalOpen");
	}

	return {};

})();