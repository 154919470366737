(function() {
	var _Root, _TagNames, _mergers,
	    __slice = [].slice,
	    __indexOf = [].indexOf || function(item) { for (var i = 0, l = this.length; i < l; i++) { if (i in this && this[i] === item) return i; } return -1; },
	    __hasProp = {}.hasOwnProperty,
	    __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; },
	    __bind = function(fn, me){ return function(){ return fn.apply(me, arguments); }; };
	
	ContentTools.AjaxDialog = (function(_super) {
		__extends(AjaxDialog, _super);
	
		function AjaxDialog(url) {
			this.url = url;
			AjaxDialog.__super__.constructor.call(this, 'Ajax');
		}
	
		AjaxDialog.prototype.mount = function() {
			var attributeNames, attributes, domActions, domTabs, lastTab, name, restricted, style, styleUI, value, _i, _j, _len, _len1, _ref;
			AjaxDialog.__super__.mount.call(this);
			ContentEdit.addCSSClass(this._domElement, 'ct-ajax-dialog');
			ContentEdit.addCSSClass(this._domView, 'ct-ajax-dialog__view');
			
		};
	
		return AjaxDialog;
	
	})(ContentTools.DialogUI);
})();