/*
 * Copyright 2019 LABOR.digital
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Last modified: 2019.05.15 at 17:17
 */

/*
 * G8.ResponsiveTable v 1.0.0
 *
 */
(function () {

	G8Framework.addGlobalListener("domChange", _initClass);

	function _initClass(scope) {
		if (_isResponsive())
			$Self(".pageContents table:not([class]), .pageContents table.table-index", scope).getUninitializedOnly("G8.ResponsiveTable").each(function () {
				var table = $(this);

				//only extend if we have more than 1 col (anywhere in the table)
				if (table.find("tr td:nth-child(1)").length > 0) {

					var
						rowHead = table.find("tr").eq(0),
						keys = [],
						tableResponsified = $(makeDiv(".tableResponsified"));

					rowHead.find("th").each(function () {
						keys.push($(this).html());
					});

					table.find("tr:gt(0)").each(function () {

						var row = $(makeDiv(".tableRow")).appendTo(tableResponsified);

						$(this).find("td").each(function (index) {

							row.append(makeDiv(".tableRow-cell", keys.length > index && $.trim(keys[index]).length > 0 ? makeDiv(".tableRow-cell-label", keys[index] + ":") : "", makeDiv(".tableRow-cell-val", $(this).html())));

						});
					});

					tableResponsified.insertAfter(table);
					table.hide();

				}
			});
	}

	function _isResponsive() {
		return $(".block-header .menuIcon:visible").length > 0;
	}

	return {};

})();