/**
 *	@author: Florian Lange <f.lange@labor.digital>
 *	LABOR – Agentur für moderne Kommunikation GmbH, Mainz, Germany
 * 	https://labor.digital
 *
 */

"use strict";

//initialize the framework...
var framework = {};

/*
 * wenn irgendein object das event triggert, wird callback( scope ) gecallt ( nicht callback( e ) )
 */
framework.addGlobalListener = function ( eventName, callback ) {
	$( document ).bind( eventName, {
			callback: callback
		},
		_ctm_globalListenerCallbackOnAnyEvent );
};

// private funcs
var _ctm_globalListenerCallbackOnAnyEvent = function () {
	var
		e = arguments[ 0 ],
		triggeringObj = $( e.target ),
		callback = e.data.callback,

		addArgs = [],
		i = 0;

	$.each( arguments, function ( key, val ) {
		if ( i > 0 )
			addArgs.push( val );
		i++;
	} );
	addArgs.length ? callback( triggeringObj, addArgs ) : callback( triggeringObj );
};

//String object methods
/*
 * parameter: entweder liste von strings ( "a", "abcd"... ) oder array ( ["a", "abc"] )
 * checkt, ob heuhaufen.indexOf( mindEinerDerNeedles )!=-1. also, ob wenigstens einer der needles im heuhaufen vorkommt
 * ist NICHT case sensitive
 */
String.prototype.hasStr = function () {
	var strs = $.isArray( arguments[ 0 ] ) ? arguments[ 0 ] : arguments;

	for ( var i = 0; i < strs.length; i++ )
		if ( this.toLowerCase().indexOf( strs[ i ].toLowerCase() ) != -1 )
			return true;

	return false;
};


//jQuery object methods
$.fn.extend( {

	//wie stop, aber danach wird NICHTS ausgeführt
	stopp: function () {
		//return( this.queue( [] ).stop() );
		return this.stop( true );
	},

	isOrIsChildOf: function ( scope ) {
		var
			boolIsOrIsChild = false,
			thisDomObj = this[ 0 ];

		scope.each( function () {
			//achtung: here this != jqueryMatchedObj
			var currScopeDomObj = this;

			if ( $.contains( currScopeDomObj, thisDomObj ) || currScopeDomObj == thisDomObj ) {
				boolIsOrIsChild = true;
				return false;
			}
		} );

		return boolIsOrIsChild;
	},

	readInputVal: function () {
		return $.trim( this.val() );
	},

	//lies teilstring aus class, die mit prefix beginnt( prefix enthält )
	//beispiel: findet MEINEID per getIdFromClass( $(), "needle" ) aus <div class="text gross needleMEINEID">
	getIdFromClass: function ( prefix ) {
		var ret = "";

		//error handling
		if ( this.prop( "class" ) ) {
			$.each( this.prop( "class" ).split( /\s+/ ), function () {
				if ( this.hasStr( prefix ) ) ret = this.replace( prefix, "" );
			} );
		}
		return ret;
	},

	bindProxy: function () {
		//arguments can be:
		//	eventType, listenerFunc, scope
		//	eventType, {additional:data}, listenerFunc, scope

		var
			eventType = arguments[ 0 ],
			additionalData = !$.isFunction( arguments[ 1 ] ) ? arguments[ 1 ] : false,
			listenerFunc = additionalData ? arguments[ 2 ] : arguments[ 1 ],
			scope = additionalData ? arguments[ 3 ] : arguments[ 2 ];

		additionalData ?
			this.bind( eventType, additionalData, $.proxy( listenerFunc, scope ) ) :
			this.bind( eventType, $.proxy( listenerFunc, scope ) );

		return this;
	},

	bindOnce: function () {
		var
			eventType = arguments[ 0 ],
			additionalData = !$.isFunction( arguments[ 1 ] ) ? arguments[ 1 ] : false,
			listenerFunc = additionalData ? arguments[ 2 ] : arguments[ 1 ];

		this.unbind( eventType, listenerFunc );

		additionalData ?
			this.bind( eventType, additionalData, listenerFunc ) :
			this.bind( eventType, listenerFunc );

		return this;
	},

	/*
	 * arguments can be:
	 * 	eventType, listenerFunc, scope
	 * 	eventType, {additional:data}, listenerFunc, scope
	 */
	bindOnceProxy: function () {
		var
			eventType = arguments[ 0 ],
			additionalData = !$.isFunction( arguments[ 1 ] ) ? arguments[ 1 ] : false,
			listenerFunc = additionalData ? arguments[ 2 ] : arguments[ 1 ],
			scope = additionalData ? arguments[ 3 ] : arguments[ 2 ];

		additionalData ?
			this.bindOnce( eventType, additionalData, $.proxy( listenerFunc, scope ) ) :
			this.bindOnce( eventType, $.proxy( listenerFunc, scope ) );

		return this;
	},

	/*
	 * naming: str
	 */
	getUninitializedOnly: function ( naming ) {
		//prevent polluting data namespace by setting "data-g8init-NAMING"=true
		var prefix = "g8init-";

		//prevent double init from parallel js-versions
		return this.filter( function () {
				return !$( this ).data( prefix + naming );
			} )
			.data( prefix + naming, true );
	},

	/*
	 * this: set of items
	 * item: the item to hilite
	 */
	hiliteItem: function ( item ) {
		var cssClass = "act";

		this.not( item ).removeClass( cssClass );
		item.addClass( cssClass );

		return this;
	}

} );

export var G8Framework = framework;

/*
 * wie $, findet aber selector angewendet auf scope-childs & scope selbst
 */
export function $Self( selector, scope ) {
	return scope.find( selector ).add( scope.filter( selector ) );
};

//Make sure, MSIE won't fall apart when not providing the console object
if ( !window.console )
	window.console = {
		log: function () {},
		group: function () {},
		groupEnd: function () {}
	};

// Define debugger
export var dbg = Function.prototype.bind.call( console.log, console );

/**
 * 	can call it like:
 * 
 * 		//multiple classes
 * 		makeDom( ".class.class2" )
 * 
 * 		//custom tags (no tag = uses <div>)
 * 		makeDom( "img.class.class2" )
 * 
 * 		//custom attributes (comma separated)
 * 		makeDom( "img.class.class2,data-attrib=something,style=background-image:url('myImage')" )
 * 
 * 		//cusotm contents (provide additional strings as arguments)
 * 		makeDom( ".class", "contents1", "contents2"... )
 * 
 */
export function makeDom() {
	var attribs = arguments.length ? arguments[ 0 ] : "";
	var attribsObj = {};

	//read tag
	var tagMatch = attribs.match( /^[^,.]*/ );
	var tag = !tagMatch || tagMatch[ 0 ] == "" ? "div" : tagMatch[ 0 ];

	//read classes
	var classAttribsMatch = attribs.match( /\.[^,]*/ );
	var classAttribs = !classAttribsMatch || classAttribsMatch[ 0 ] == "" ? false : classAttribsMatch[ 0 ];
	if ( classAttribs )
		attribsObj[ "class" ] = $.trim( classAttribs.split( "." ).join( " " ) );

	//read optional attributes
	var furtherAttribsMatch = attribs.match( /,.*/ );
	var furtherAttribs = !furtherAttribsMatch || furtherAttribsMatch[ 0 ] == "" ? false : furtherAttribsMatch[ 0 ];
	if ( furtherAttribs )
		$.each( furtherAttribs.split( "," ), function ( index, attr ) {
			if ( index > 0 )
				attribsObj[ attr.split( "=" )[ 0 ] ] = attr.split( "=" )[ 1 ];
		} );

	//convert to dom string
	var attrStringified = "";
	$.each( attribsObj, function ( attrName, attrVal ) {
		attrStringified += ' ' + attrName + '="' + attrVal + '"';
	} );

	//flatten contents
	var contents = $.makeArray( arguments );
	contents.shift();
	contents = contents.join( "" );

	return '<' + tag + attrStringified + '>' + contents + '</' + tag + '>';
};

//legacy support, will use makeDom() internally
export function makeDiv() {
	return makeDom.apply( null, arguments );
};