/*
 * Copyright 2019 LABOR.digital
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Last modified: 2019.05.15 at 17:17
 *
 */
import datepicker from 'js-datepicker'
import 'js-datepicker/dist/datepicker.min.css';

(function( ) {
	G8Framework.addGlobalListener("domChange", _initClass);

	function _initClass(scope) {
		$Self("*[data-max-date]", scope).getUninitializedOnly("G8.Modal").each(function (e) {
			const input = $(this)[0];
			const now = new Date();
			const created = $('input[name="data[Job][created]"]').val();
			const startDate = created === undefined ? new Date() : new Date(created);

			let maxDate = null;

			if(created !== undefined) {
				maxDate = new Date(created);
				maxDate.setDate(maxDate.getDate() + 6 * 7);
			} else {
				maxDate = new Date();
				maxDate.setDate(startDate.getDate() + 6 * 7);
			}

			$(this)[0].disabled = maxDate < now;
			const picker = datepicker(input, {
				formatter: (input, date, instance) => {
					input.value = date.toISOString().slice(0, 10);
				},
				respectDisabledReadOnly: maxDate < now
			},)

			picker.setMin(startDate);
			picker.setMax(maxDate);
		});
	}

	return {};

})();