/*
 * Copyright 2019 LABOR.digital
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Last modified: 2019.05.15 at 17:17
 */
(function () {

	G8Framework.addGlobalListener("domChange", _initClass);

	var init = false;

	function _initClass(scope) {
		if (!init) {
			init = true;
			$("body, html").animate({scrollTop: 0}, 0);
			_onHashChange(location.hash, true);

			$(window).bind("hashchange", function (e) {
				e.preventDefault();
				_onHashChange(location.hash, false);
				return false;
			});

			$("a[href^='#']").bind("click", function (e) {
				e.preventDefault();
				e.stopPropagation();
				var hash = $(this).attr("href");
				_onHashChange(hash, true);
				return false;
			});
		}
	}

	function _onHashChange(hash, animated) {
		animated = animated ? true : false;
		if (hash) {
			var elem = $(hash);
			if (elem.length)
				$("body, html").animate({scrollTop: elem.offset().top - $("header.block-header").height() - 25}, animated ? 450 : 0);
		}
	}

	return {};

})();