/*
 * Copyright 2019 LABOR.digital
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Last modified: 2019.05.15 at 17:17
 */

(function () {

	G8Framework.addGlobalListener("domChange", _initClass);

	function _initClass(scope) {
		$Self("*[data-bookmark]", scope).getUninitializedOnly("G8.Bookmark.elem").each(function (e) {
			var bookmark = $(this);
			var delCtrl = bookmark.find("*[data-bookmark-deleteurl]");

			delCtrl.bind("click", function (e) {
				e.stopPropagation();
				e.preventDefault();

				$.ajax({
					type: "GET",
					url: delCtrl.data("bookmarkDeleteurl"),
					success: function (data, textStatus, jqXHR) {
						bookmark.remove();
					},
					error: function (jqXHR, textStatus, errorThrown) {
						alert(jqXHR.responseJSON.message);
					},
					complete: function () {

					},
					dataType: "json"
				});

				return false;
			});
		});

		$Self("*[data-bookmark-addurl]", scope).getUninitializedOnly("G8.Bookmark.add").bind("click", function (e) {
			e.stopPropagation();
			e.preventDefault();

			var add = $(this);

			$.ajax({
				type: "GET",
				url: add.data("bookmarkAddurl"),
				success: function (data, textStatus, jqXHR) {
					alert(data["return"]["message"]);
				},
				error: function (jqXHR, textStatus, errorThrown) {
					alert(jqXHR.responseJSON.message);
				},
				complete: function () {

				},
				dataType: "json"
			});

			return false;
		});
	}

	return {};

})();