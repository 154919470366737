/*
 * Copyright 2019 LABOR.digital
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Last modified: 2019.05.15 at 17:17
 */

/*
markup like this:

	.g8_toggleable | optional can use .g8_toggleable_single
		.g8_toggleable_toggler
		.g8_toggleable_toggledContents

*/
import _ from "./underscore";

(function () {

	G8Framework.addGlobalListener("domChange", _initClass);

	function _initClass(scope) {

		$Self(".g8_toggleable", scope).getUninitializedOnly("toggleable").each(function () {
			new Toggleable($(this));
		});
	}

	function _filterChildrenOf(jObj, childJObj) {
		return jObj.filter(function () {
			var keep = true;
			var content = this;

			childJObj.each(function () {
				if ($.contains(this, content)) {
					keep = false;
					return false;
				}
			});
			return keep;
		});
	}


	//constructor
	var Toggleable = function (jObj) {
		var self = this;

		this.cssClassExp = "exp";

		this.container = jObj;
		this.toggledContents = this.container.find(".g8_toggleable_toggledContents");
		this.toggler = this.container.find(".g8_toggleable_toggler");

		this.toggledContents = _filterChildrenOf(this.toggledContents, this.container.find(".g8_toggleable"));
		this.toggler = _filterChildrenOf(this.toggler, this.container.find(".g8_toggleable"));

		this.singleMode = this.container.hasClass("g8_toggleable_single");

		//bind scope for all listeners
		_.bindAll(this, "_clk_toggler", "_ctm_collapse");

		this.toggler.on("click", this._clk_toggler);
		this.container.on("toggleable_collapse", this._ctm_collapse);

		return this;
	};

	//methods
	Toggleable.prototype = {

		_ctm_collapse: function (eProxy) {
			this._toggle(false);
		},

		_clk_toggler: function (eProxy) {
			eProxy.preventDefault();

			var exp = !this.container.hasClass(this.cssClassExp);

			this._toggle(exp);
		},

		_toggle: function (exp) {
			var self = this;

			if (exp) {
				this.container.addClass(this.cssClassExp);
				this.toggledContents.slideDown(300);

				if (this.singleMode)
					this.container.siblings(".g8_toggleable").trigger("toggleable_collapse");

				this.toggler.trigger("toggleExp");

			} else {
				this.container.removeClass(this.cssClassExp);
				this.toggledContents.slideUp(300);

				this.toggler.trigger("toggleCol");
			}
		}

	};


	//return public methods & properties
	return {};

})();