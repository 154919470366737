/*
 * Copyright 2019 LABOR.digital
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Last modified: 2019.05.15 at 17:17
 */
(function () {

	G8Framework.addGlobalListener("domChange", _initClass);

	function _initClass(scope) {
		$Self("*[data-pagenode-reloadurl]", scope).getUninitializedOnly("G8.Pagenode.reload").bind("pagenodeReload", function (e) {
			e.preventDefault();

			var pagenode = $(this);

			$.ajax({
				type: "GET",
				url: pagenode.data("pagenodeReloadurl"),
				success: function (data, textStatus, jqXHR) {
					var $data = $(data);
					pagenode.replaceWith($data);
					$(document).trigger("domChange");
				},
				error: function (jqXHR, textStatus, errorThrown) {

				},
				complete: function () {

				},
				dataType: "html"
			});

			return false;
		});
	}

	return {};

})();