/*
 * Copyright 2019 LABOR.digital
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Last modified: 2019.05.15 at 17:17
 */

(function () {

	G8Framework.addGlobalListener("domChange", _initClass);

	function _initClass(scope) {
		$Self("table[data-table-sortable]", scope).getUninitializedOnly("G8.Table.sortable").each(function () {
			var table = $(this);
			var sortLinks = table.find("th[data-table-sortable-idx]");

			sortLinks.each(function () {
				var sortLink = $(this);
				var startIdxDirection = sortLink.data("tableSortableStartidxDirection");
				if (!startIdxDirection)
					startIdxDirection = "";
				sortLink.html("<a href=\"javascript:;\" class=\"tableSortable-link " + startIdxDirection + "\">" + sortLink.text() + "</a>");
			});

			sortLinks = table.find("th[data-table-sortable-idx] a");
			sortLinks.bind("click", function () {
				var sortLink = $(this);
				var rows = table.find("tr[data-table-sortable-row]");
				var rowsParent = rows.parent();
				var direction = "asc";
				if (sortLink.hasClass("asc"))
					direction = "desc";
				sortLinks.removeClass("asc").removeClass("desc");
				sortLink.addClass(direction);
				var idx = sortLink.closest("th").data("tableSortableIdx");

				var rowsInOrder = [];
				rows.each(function (k, v) {
					rowsInOrder.push({
						idx: k,
						val: $(v).find("td[data-table-sortable-idx=" + idx + "]").data("tableCellVal")
					});
				});
				rowsInOrder.sort(function (a, b) {
					if (direction == "asc" && a.val < b.val)
						return -1;
					else if (direction == "asc" && a.val > b.val)
						return 1;
					else if (direction == "desc" && a.val < b.val)
						return 1;
					else if (direction == "desc" && a.val > b.val)
						return -1;
					else
						return 0;
				});
				var newRowsHtml = "";
				$.each(rowsInOrder, function (k, v) {
					newRowsHtml += rows.eq(v.idx).appendTo(rowsParent);
				});
			});
		});

		$Self("table[data-table-searchable]", scope).getUninitializedOnly("G8.Table.searchable").each(function () {
			var table = $(this);
			var searchRows = table.find("tr[data-table-searchable-row]");
			var searchInput = table.find("input[data-table-searchable-input]");

			if (searchInput.length && searchRows.length) {
				var delayTimer;
				searchInput.bind("keyup", function () {
					clearTimeout(delayTimer);

					delayTimer = setTimeout(function () {
						var searchVal = searchInput.val().toLowerCase();
						searchRows.each(function () {
							var row = $(this);

							var match = false;
							row.find("td[data-table-cell-val]").each(function () {
								if ($(this).data("tableCellVal").indexOf(searchVal) != -1) {
									match = true;
									return false;
								}
							});

							if (!match)
								row.hide();
							else
								row.show();
						});
					}, 250);
				});
			}
		});
	}

	return {};

})();