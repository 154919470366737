/*
 * Copyright 2019 LABOR.digital
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Last modified: 2019.05.15 at 17:17
 */
(function () {

	G8Framework.addGlobalListener("domChange", _initClass);

	var
		sidebar,
		expandedContent,
		sidebarCover;

	function _initClass(scope) {
		//check dependency

		sidebar = $(".g8_sidebar_sidebar");
		expandedContent = $(".g8_sidebar_expandedContent");

		$Self(".g8_sidebar_btnToggleSidebar", scope).bindOnce("click", _onToggleSidebar);
	}

	function _onToggleSidebar(e) {
		var show = !sidebar.hasClass("exp");

		if (show) {
			sidebar.addClass("exp");
			if (!sidebarCover)
				sidebarCover = $(makeDiv(".sidebarCover")).appendTo("body").bindOnce("click", _onToggleSidebar);
			sidebarCover.stop().fadeIn(300);
		} else {
			sidebar.removeClass("exp");
			sidebarCover.stop().fadeOut(300);
		}
	}

	// return public methods & properties
	return {};

})();